import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router-dom";
import Storenav from "./Storenav";
import "./style.css";
import axios from "axios";

export default function Product() {
  const [unit, setUnit] = useState(10);
  const [unitpage, setUnitpage] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // Get storeName from the URL query parameter
  const location = useLocation();
  const storename = new URLSearchParams(location.search).get("storeName");

  // Effect hook to check if the user is logged in and fetch data
  useEffect(() => {
    if (!localStorage.getItem("userlogin")) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        var body = {
          storename: storename,
        };
        axios
          .post(`https://universole-api.webgarh.net/shopify-api/products`, body)
          .then((response) => {
            // Check the status of the response
            if ((response.data.message = "sucess")) {
              setData(response.data.data);
            } else {
              console.log(response.data.message);
            }
          });
      } catch (error) {
        // Handle errors that occur during the API request
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Handle page change for pagination
  const handlepaginationchange = (event) => {
    setUnitpage(event.selected);
  };

  // Filter and map products data for displaying in the table
  const productData = data
    .filter((item) => {
      const id = item.data.id;
      const title = item.data.title;
      const price = item.data.variants[0].price;

      const idMatch = typeof id === "string" && id.includes(search);
      const titleMatch =
        typeof title === "string" &&
        title.toLowerCase().includes(search.toLowerCase());
      const priceMatch = typeof price === "string" && price.includes(search);

      return idMatch || titleMatch || priceMatch;
    })
    .map((item, index) =>
      index >= unitpage * unit && index < unit * (1 + unitpage) ? (
        <tr key={index}>
          <td>{item.data.id}</td>
          <td>
            {item.data.image && item.data.image.src ? (
              <img
                src={item.data.image.src}
                alt={`Image for ${item.data.image.src || "Product"}`}
                style={{ width: "40px", height: "38px", borderRadius: "10px" }}
              />
            ) : (
              <span>No Image</span>
            )}
          </td>
          <td>{item.data.title}</td>
          <td>$ {item.data.variants[0].price}</td>
        </tr>
      ) : null
    );

  // Navigate back to the store
  function handlebackbutton() {
    navigate("/store");
  }

  // Handle select change for number of entries
  function handleselect(event) {
    const selectedUnit = parseInt(event.target.value, 10);
    console.log("value", selectedUnit);
    setUnit(selectedUnit);
    setUnitpage(0);
  }

  return (
    <>
      <Storenav />
      <div className="pro-sear">
        <h5 className="main-h5">{storename}</h5>
        <button className="pro-back pr1" onClick={handlebackbutton}>
          Back
        </button>
      </div>
      <div className="propa-fdiv">
        <div className="pro-bacdiv">
          <div className="dataTables_length" id="example_length">
            <label>
              Show
              <select
                name="example_length"
                value={unit}
                onChange={handleselect}
                aria-controls="example"
                className="form-select form-select-sm"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{" "}
              entries
            </label>
          </div>
          <input
            id="search-focus"
            type="search"
            placeholder="Search "
            aria-label="Search"
            aria-describedby="basic-addon1"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="order-fdiv pro-tab">
          <div className="table-div ">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Product Id</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>{productData}</tbody>
            </table>
          </div>

          <div className="pro-div">
            <div className="pagin">
              <p>
                Showing {unitpage + 1} to {unit * (1 + unitpage)} of{" "}
                {data.length} entries
              </p>
            </div>
            <div className="pag-div">
              {productData.length > 0 ? (
                <ReactPaginate
                  nextLabel={
                    <div>
                      <span>Next</span>
                    </div>
                  }
                  previousLabel={
                    <div>
                      <span>Previous</span>
                    </div>
                  }
                  onPageChange={handlepaginationchange}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={
                    productData.length > unit ? productData.length / unit : 0
                  }
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
