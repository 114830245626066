import React from "react";
import "./style.css";
import userlogo from "../assets/admin.png";
import logoutlogo from "../assets/logout.png";
import { useNavigate } from "react-router-dom";

export default function Storenav({ children }) {
  const navigate = useNavigate();

  // Function to handle user logout
  function logout() {
    localStorage.removeItem("userlogin");
    navigate("/login");
  }

  return (
    <div>
      <header className="header-nav logged-header-nav">
        <div className="w-50-header">
          <div className="nav-text-logo">universole fit</div>
        </div>

        <div className="dropdown">
          <button
            className="btn dropdown-toggle user-login dropbtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={userlogo} alt="" />
            Admin
          </button>
          <ul className="dropdown-menu dropdown-content dropdiv">
            <li onClick={logout}>
              <img className="logout" src={logoutlogo} alt="" /> Log Out
            </li>
          </ul>
        </div>
      </header>

      <main className="right-section">{children}</main>
    </div>
  );
}
