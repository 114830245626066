import React, { useState, useEffect } from "react";
import "./order.css";
import ReactPaginate from "react-paginate";
import Storenav from "./Storenav";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default function Order() {
  const [unit, setUnit] = useState(10);
  const [unitpage, setUnitpage] = useState(0);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  console.log("orderdata", data);

  // Get storeName from the URL query parameter
  const location = useLocation();
  const storename = new URLSearchParams(location.search).get("storeName");

  // Effect hook to check if the user is logged in and fetch data
  useEffect(() => {
    const storeName = localStorage.getItem("storeName");
    if (!localStorage.getItem("userlogin")) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        var body = {
          storename: storeName,
        };
        axios
          .post(`https://universole-api.webgarh.net/shopify-api/orders`, body)
          .then((response) => {
            // Check the status of the response
            if ((response.data.message = "success")) {
              setData(response.data.data);
            }
          });
      } catch (error) {
        // Handle errors that occur during the API request
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Handle page change for pagination
  const handlepaginationchange = (event) => {
    setUnitpage(event.selected);
  };

  // Filter and map order data for displaying in the table
  const orderData = data

    .filter(
      (item) =>
        (item.id && typeof item.id === "string" && item.id.includes(search)) ||
        (item.created_at &&
          typeof item.created_at === "string" &&
          item.created_at.includes(search)) ||
        (item.customer &&
          item.customer.id &&
          typeof item.customer.id === "string" &&
          item.customer.id.includes(search)) ||
        (item.item &&
          item.item.financial_status &&
          typeof item.item.financial_status === "string" &&
          item.item.financial_status
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (item.line_items[0]?.fulfillable_quantity &&
          typeof item.line_items[0].fulfillable_quantity === "string" &&
          item.line_items[0].fulfillable_quantity
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (item.total_price &&
          typeof item.total_price === "string" &&
          item.total_price.includes(search))
    )

    .map((item, index) =>
      index >= unitpage * unit && index < unit * (1 + unitpage) ? (
        <tr key={index}>
          <td
            className="order-td"
            onClick={() => orderidclick(item.id, item.data.name)}
          >
            {item.data.name}
          </td>
          <td>
            {item.created_at
              ? moment(item.created_at).format("DD/MM/yyyy")
              : ""}
          </td>
          <td>{item.customer.id}</td>
          <td>$ {item.total_price}</td>
          <td
            style={{
              color:
                item.paymentStatus === "Authorized"
                  ? "rgb(225 180 24)"
                  : item.paymentStatus === "Paid"
                  ? "rgb(93 223 106)"
                  : "#3B3B3B",
            }}
          >
            {item.financial_status}
          </td>

          <td>
            {parseInt(item.line_items[0].fulfillable_quantity, 10) === 1
              ? `${item.line_items[0].fulfillable_quantity} item`
              : `${item.line_items[0].fulfillable_quantity} items`}
          </td>
        </tr>
      ) : null
    );

  // Navigate back to the store
  function handlebackbutton() {
    navigate("/store");
  }

  //Navigate and pass orderid
  function orderidclick(order, oname) {
    navigate(`/orderdatails?orderid=${order}&name=${oname.replace("#", "")}`);
  }

  // Handle select change for number of entries
  function handleselect(event) {
    const selectedUnit = parseInt(event.target.value, 10);
    console.log("value", selectedUnit);
    setUnit(selectedUnit);
    setUnitpage(0);
  }

  return (
    <>
      <Storenav />
      <div className="ord-back">
        <h5 className="main-h5">{storename}</h5>
        <button className="pro-back" onClick={handlebackbutton}>
          Back
        </button>
      </div>
      <div className="order-fdiv">
        <div className="order-fdiv1">
          <div className="order-fdiv1input">
            <div className="dataTables_length" id="example_length">
              <label>
                Show
                <select
                  name="example_length"
                  value={unit}
                  onChange={handleselect}
                  aria-controls="example"
                  className="form-select form-select-sm"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>{" "}
                entries
              </label>
            </div>

            <div className="ord-ser">
              <input
                id="search-focus"
                type="search"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="table-div order-tab">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Order</th>
                <th scope="col">Date</th>
                <th scope="col">Customer Id</th>
                <th scope="col">Total</th>
                <th scope="col">Payment status</th>
                <th scope="col">Items</th>
              </tr>
            </thead>
            <tbody>{orderData}</tbody>
          </table>

          <div className="pro-div">
            <div className="pagin">
              <p>
                Showing {unitpage + 1} to {unit * (1 + unitpage)} of{" "}
                {data.length} entries
              </p>
            </div>
            <div className="pag-div">
              {orderData.length > 0 ? (
                <ReactPaginate
                  nextLabel={
                    <div>
                      <span>Next</span>
                    </div>
                  }
                  previousLabel={
                    <div>
                      <span>Previous</span>
                    </div>
                  }
                  onPageChange={handlepaginationchange}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={
                    orderData.length > unit ? orderData.length / unit : 0
                  }
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
