import React from "react";
import "./style.css";

export default function Header({ children }) {
  return (
    <div>
      <header className="header-nav">
        <div className="nav-text-logo">Universole fit</div>
      </header>
    </div>
  );
}
