import React, { useState, useEffect } from "react";
import Storenav from "./Storenav";
import "./orderdetails.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default function Orderdetails() {
  const navigate = useNavigate();
  const [dataa, setDataa] = useState([]);

  // Get order details from URL query parameters
  const location = useLocation();
  const orderid = new URLSearchParams(location.search).get("orderid");
  const ordername = new URLSearchParams(location.search).get("name");

  // Filter data based on the order ID
  let data = dataa.filter((item) => {
    return item.id === Number(orderid);
  });

  // Effect hook to check if the user is logged in and fetch data
  useEffect(() => {
    if (!localStorage.getItem("userlogin")) {
      navigate("/login");
    }
    const storeName = localStorage.getItem("storeName");

    const fetchData = async () => {
      try {
        var body = {
          storename: storeName,
        };
        axios
          .post(`https://universole-api.webgarh.net/shopify-api/orders`, body)
          .then((response) => {
            // Check the status of the response
            if ((response.data.message = "success")) {
              setDataa(response.data.data);
            }
          });
      } catch (error) {
        // Handle errors that occur during the API request
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Prepare order data for order information
  const orderData = data.map((item, index) => (
    <tr key={index}>
      <td>{item.storeName}</td>
      <td>{item.id}</td>
      <td>
        {item.created_at ? moment(item.created_at).format("DD/MM/yyyy") : ""}
      </td>
    </tr>
  ));

  const sumsArray = [];
  const sumsArray1 = [];
  const orderData1 = data.map((item, index) => {
    const lineItemPrice = parseFloat(item.line_items[0].price);
    const totalTax = parseFloat(item.data.total_tax);
    const totalShipping = parseFloat(
      item.data.total_shipping_price_set.shop_money.amount
    );

    const appcharge = parseFloat(item.application_charges);
    const sum = lineItemPrice + totalShipping;

    const sub = sum - appcharge;

    sumsArray.push(sum);
    sumsArray1.push(sub);
    return (
      <tr key={index}>
        <td>{item.line_items[0].name}</td>
        <td>{item.line_items[0].quantity}</td>
        <td>$ {item.line_items[0].price}</td>
        <td>$ {item.data.total_shipping_price_set.shop_money.amount}</td>
        <td>$ {sum}</td>
      </tr>
    );
  });

  // Handle back button click
  function handlebackbutton() {
    navigate("/order");
  }

  return (
    <div>
      <Storenav />
      <div className="ordet-back">
        <h5 className="detmain-h5">#{ordername}</h5>
        <button className="pro-back" onClick={handlebackbutton}>
          Back
        </button>
      </div>
      <div className="orderdet-fdiv">
        <div className="orderdet-fdiv1">
          <span className="order-span">Order</span>
          <div className="orderdet-fdiv2">
            <div className="table-div order-tab ">
              <table className="table orderdet1">
                <thead>
                  <tr>
                    <th scope="col">Store Name</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Order Creation Date</th>
                  </tr>
                </thead>
                <tbody>{orderData}</tbody>
              </table>
              <hr className="orderspan" />
              <table className="table orderdet order-t1">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Unit Price </th>
                    <th scope="col">Shipping Cost</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>{orderData1}</tbody>
              </table>
              {data.map((item, index) => (
                <div className="total-container" key={index}>
                  <div className="total-row">
                    <span className="total-label">Total:</span> ${sumsArray}
                  </div>
                  <div className="total-row">
                    <span className="total-label">Application Changes:</span>$
                    {item.application_charges}
                  </div>

                  <hr />
                  <div className="total-row Grand_total">
                    <span className="total-label1">Grand Total:</span>{" "}
                    <b> ${sumsArray1}</b>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
