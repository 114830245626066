import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import userlogo from "../assets/profile-user (1).png";
import messagelogo from "../assets/input-icon1.png";
import passlogo from "../assets/input-icon2.png";
import Header from "./Header";
import axios from "axios";
import { useAlert } from "react-alert";

export default function Login() {
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  // Function to handle email and password input changes
  const emailpasswordchange = (e) => {
    const { name, value } = e.target;

    // Reset error flags when the user types
    if (name === "email") {
      setEmailError(false);
    } else if (name === "password") {
      setPasswordError(false);
    }
    // Update state based on user input
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  // Check if the user is already logged in
  useEffect(() => {
    if (localStorage.getItem("userlogin")) {
      navigate("/store");
    }
  });

  // Handle login form submission
  const loginformSubmit = (e) => {
    e.preventDefault();
    // Validate user input
    setEmailError(email === "");
    setPasswordError(password === "");

    if (email !== "" && password !== "") {
    }
  };

  // Handle login button click
  async function Loginclick() {
    const url = "https://universole-api.webgarh.net/api/login";

    try {
      const response = await axios.post(
        url,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
       // Check the status of the response
      if (response.data.status === true) {
        alert.show("Login successful");
        localStorage.setItem("userlogin", email);
        navigate("/store");
      } else {
        alert.show(response.data.message);
        console.log("Login failed:", response.data.message);
      }
    } catch (error) {
       // Handle errors that occur during the API request
      if (error.response && error.response.status === 404) {
        console.log("Request failed with status code 404");
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  }

  return (
    <div>
      <Header />
      <section className="uni-login-form" id="crosslogin">
        <div className="uni-login-form-box">
          <div className="login-heading-image">
            <img src={userlogo} alt="" />
            <h2>Log In</h2>
          </div>
          <form onSubmit={loginformSubmit}>
            <div className="login-input-bx">
              <label htmlFor="email">Email</label>
              <span>
                <img
                  src={messagelogo}
                  alt=""
                  className={passwordError ? "logimg" : ""}
                />
                <input
                  type="email"
                  placeholder="Enter email address"
                  name="email"
                  value={email}
                  onChange={emailpasswordchange}
                  className={emailError ? "error" : ""}
                  aria-invalid={emailError}
                />
                {emailError && (
                  <label id="email-error" className="error" htmlFor="email">
                    Please enter a valid email address
                  </label>
                )}
              </span>
            </div>
            <div className="login-input-bx minus-margin">
              <label htmlFor="password">Password</label>
              <span>
                <img
                  src={passlogo}
                  alt=""
                  className={passwordError ? "logimg" : ""}
                />
                <input
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onChange={emailpasswordchange}
                  className={passwordError ? "error" : ""}
                  aria-invalid={passwordError}
                />
                {passwordError && (
                  <label
                    id="password-error"
                    className="error"
                    htmlFor="password"
                  >
                    Please enter a valid password
                  </label>
                )}
              </span>
            </div>
            <div className="remember-forgiot">
              <div className="rememberr">
                <label htmlFor="remember">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  Remember me
                </label>
              </div>
            </div>
            <div className="subbtn">
              <button type="submit" onClick={Loginclick}>
                Log In
                <i className="fas fa-arrow-circle-right"></i>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
