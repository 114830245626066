import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Login from './Login';
import Storenav from './Storenav';
import Store from './Store';
import Order from './Order';
import Product from './Product';
import Orderdatails from './Orderdetails';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/header" element={<Header />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/order" element={<Order />} />
        <Route path="/product" element={<Product />} />
        <Route path="/orderdatails" element={<Orderdatails />} />
        <Route
          path="/store"
          element={
            <Storenav>
              <Routes>
                <Route index element={<Store />} />
             
              </Routes>
            </Storenav>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
