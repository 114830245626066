import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./style.css";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import axios from "axios";
import { useAlert } from "react-alert";

export default function Store() {
  const alert = useAlert();
  const [unit, setUnit] = useState(10);
  const [unitpage, setUnitpage] = useState(0);
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const navigate1 = useNavigate();

  // Function to fetch store data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://universole-api.webgarh.net/shopify-api/store/request"
      );
      // Check the status of the response
      if (response.data.status == true) {
        setData(response.data.storeData);
      }
    } catch (error) {
      // Handle errors that occur during the API request
      console.log(error);
    }
  };

  // Effect hook to check if the user is logged in and fetch data
  useEffect(() => {
    if (!localStorage.getItem("userlogin")) {
      navigate("/login");
    }
    fetchData();
  }, []);

  // Handle page change for pagination
  const handlepaginationchange = (event) => {
    setUnitpage(event.selected);
  };

  const [value, setvalue] = useState("");
  const openModal = (e) => {
    ModalManager.open(
      <ReactModal text={value} rowIndex={e} onRequestClose={() => true} />
    );
  };

 
  // Filter and map store data for displaying in the table
  const storeDatas = data
    .filter(
      (item) =>
        item.storeName.toLowerCase().includes(search.toLowerCase()) ||
        item.onboardingStatus
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.installationDate.includes(search)
    )
    .filter((item) =>
      filterStatus === ""
        ? true
        : item.onboardingStatus.toString().toLowerCase() === filterStatus
    )
    .map((item, index) => {
      const sno = index + 1;
      return index >= unitpage * unit && index < unit * (1 + unitpage) ? (
        <tr key={index}>
          <td>{sno}</td>
          <td>{item.storeName}</td>
          <td>{item.onboardingStatus ? "Approved" : "Pending"}</td>
          <td>
            {item.installationDate
              ? moment(item.installationDate).format("DD/MM/yyyy")
              : ""}
          </td>
          <td>
            <button
              onClick={() => productclick(item.storeName)}
              style={{ width: "112px" }}
            >
              <span className="material-symbols-outlined">
                <i className="fas fa-box-full"></i>
              </span>
              Products
            </button>
            <button onClick={() => orderclick(item.storeName)}>
              <span className="material-symbols-outlined">
                <i className="far fa-shopping-bag"></i>
              </span>
              Orders
            </button>
          </td>
          <td>
            {item.onboardingStatus ? (
              <span>Approved</span>
            ) : (
              <button
                className="ap-btn"
                onClick={() => openModal(item.storeId)}
              >
                Approve
              </button>
            )}
          </td>
        </tr>
      ) : null;
    });

  // Function to handle entries per page change
  function handleselect(event) {
    const selectedUnit = parseInt(event.target.value, 10);
    setUnit(selectedUnit);
    setUnitpage(0);
  }

  // Function to handle click on order button
  function orderclick(storeName) {
    localStorage.setItem("storeName", storeName);
    navigate(`/order?storeName=${storeName}`);
  }

  // Function to handle click on product button
  function productclick(storeName) {
    navigate1(`/product?storeName=${storeName}`);
  }

  // Function to render modal content
  function ReactModal({ text, rowIndex, onRequestClose }) {
    return (
      <div className="templo">
        <Modal onRequestClose={onRequestClose} effect={Effect.Fall}>
          <div className="icons-sec"></div>
          <h1 className="modal-h1">Would you like to give your approval?</h1>
          <div className="modal-right-section">
            <button
              className="mclosebtn apro-abtn"
              onClick={() => aproveclick(rowIndex)}
            >
              Yes
            </button>
            <button
              className="mclosebtn apro-cbtn"
              onClick={ModalManager.close}
            >
              No
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  // Function to handle store approval click
  async function aproveclick(rowIndex) {
    const url = "https://universole-api.webgarh.net/shopify-api/approve";

    try {
      const response = await axios.post(
        url,
        { id: rowIndex },
        { headers: ("Content-Type", "application/x-www-form-urlencoded") }
      );
      if (response.data.status === true) {
        alert.show("Approved Successfully");
        fetchData();
        ModalManager.close();
      } else {
        ModalManager.close();
        alert.show(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  return (
    <div>
      <section className="cross-login-form stores-list-sec" id="storeslist">
        <div className="table_header_sec_store_list">
          <h1>Stores List</h1>
        </div>
        <div className="pro-fdiv">
          <div className="sto-ser">
            <div className="dataTables_length" id="example_length">
              <label>
                Show
                <select
                  name="example_length"
                  value={unit}
                  onChange={handleselect}
                  aria-controls="example"
                  className="form-select form-select-sm"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>{" "}
                entries
              </label>
            </div>

            <div className="filter_btn">
              <div className="search_bar">
                <input
                  id="search-focus"
                  type="search"
                  className="form-control"
                  placeholder="Search "
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="table_reponsive">
            <div className="store-fdiv">
              <div className="table-div">
                <table className="table table-bordered table-div1 table-div2 ">
                  <thead>
                    <tr>
                      <th className="Seral_no">S.No</th>
                      <th className="Store_name_title">Store Name</th>
                      <th className="Store_Status">Onboarding Status</th>
                      <th className="Installation Date">Installation Date</th>
                      <th>Action</th>
                      <th>App Approval Status</th>
                    </tr>
                  </thead>
                  <tbody>{storeDatas}</tbody>
                </table>
              </div>
            </div>

            <div className="pro-div">
              <div className="pagin">
                <p>
                  Showing {unitpage + 1} to {unit * (1 + unitpage)} of{" "}
                  {data.length} entries
                </p>
              </div>
              <div className="pag-div">
                {storeDatas.length > 0 ? (
                  <ReactPaginate
                    nextLabel={
                      <div>
                        <span>Next</span>
                      </div>
                    }
                    previousLabel={
                      <div>
                        <span>Previous</span>
                      </div>
                    }
                    onPageChange={handlepaginationchange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={
                      storeDatas.length > unit ? storeDatas.length / unit : 0
                    }
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
